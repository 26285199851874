"use client";

import { DemoSiteBanner } from "components/DemoSiteBanner";
import { EmailVerificationMessage } from "components/EmailVerificationMessage";
import { CookieNotice } from "components/site/CookieNotice";
import { Footer } from "components/site/Footer";
import { Header } from "components/site/Header";
import { SiteDisabled } from "components/site/SiteDisabled";
import { UpdateRequiredAccountDetailsModal } from "components/site/UpdateRequiredAccountDetailsModal";
import { isDemoSite } from "consts/client";
import { ReadyWaitlistEntriesMessage } from "features/waitlists/components/ReadyWaitlistEntriesMessage";
import { usePathname, useSearchParams } from "next/navigation";
import type { ReactNode } from "react";
import type { Client } from "types/model/client";
import type { CurrentUser } from "types/model/user";
import { cn } from "utils/cn";

/**
 * Layout for public pages used by customers.
 */
export function PublicLayout({
  client,
  currentUser,
  children,
  addContainer
}: {
  client: Client;
  currentUser: CurrentUser | null;
  children: ReactNode;
  addContainer?: boolean;
}) {
  const pathname = usePathname();

  const preventAccessIfClientDisabledList = [
    "/",
    "/cart",
    "/register",
    "/checkout/payment",
    "/checkout/complete",
    "/checkout/attendee",
    "/checkout/attendee"
  ];
  const preventAccessIfClientDisabled =
    preventAccessIfClientDisabledList?.includes(pathname || "");

  const shouldAddContainer =
    addContainer ?? (pathname?.includes("/activity") ? false : true);
  const isIframe = Boolean(pathname?.includes("/iframe"));

  const searchParams = useSearchParams();
  const useTransparentBg = searchParams?.get("useTransparentBg") === "true";

  const shouldUseWhiteBg = useTransparentBg;

  return (
    <>
      {isDemoSite && !isIframe && <DemoSiteBanner />}
      {!isIframe && <Header client={client} currentUser={currentUser} />}
      {client.enabled === false && preventAccessIfClientDisabled ? (
        <SiteDisabled />
      ) : (
        <div
          className={cn(
            "min-h-[calc(100vh-88px-405px)]",
            !shouldUseWhiteBg && "bg-gray-100",
            shouldAddContainer && !isIframe && "py-8 lg:py-12",
            !shouldAddContainer && !isIframe && "pb-8 lg:pb-12",
            isIframe && "min-h-screen py-6"
          )}
        >
          {shouldAddContainer && (
            <div
              className={cn("mx-auto max-w-7xl px-4", !isIframe && "sm:px-6")}
            >
              <section className="flex flex-col gap-4 px-2 sm:px-6 lg:px-0">
                <EmailVerificationMessage />
                <ReadyWaitlistEntriesMessage className="mb-6" />
              </section>
              {children}
            </div>
          )}
          {!shouldAddContainer && (
            <>
              <section className="flex flex-col">
                <div className="w-full bg-yellow-50">
                  <EmailVerificationMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
                <div className="w-full bg-blue-50">
                  <ReadyWaitlistEntriesMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
              </section>
              {children}
            </>
          )}
          <CookieNotice client={client} isIframe={isIframe} />
        </div>
      )}
      {!isIframe && (
        <Footer client={client} shouldAddTopBorder={shouldUseWhiteBg} />
      )}
      {currentUser && (
        <UpdateRequiredAccountDetailsModal
          currentUser={currentUser}
          client={client}
          isOpen={Boolean(currentUser?.missingRequiredFieldIds?.length)}
        />
      )}
    </>
  );
}
